//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';
import VInput from '@/components/UI/VInput';
import VButton from '@/components/UI/VButton';
import { V_BUTTON } from '@/utils/constants/UI';

import { createReferralLink } from '@/requestManager/cabinet/referrals/referralRequests'; 
import Cookies from 'js-cookie';

export default {
  name: 'CreateReferralLinkModal',
  components: {
    VIcon,
    VInput,
    VButton,
  },
  data: () => ({
    V_BUTTON,
    referral_type: '',
    referralTypeHasError: false,
    referralTypeTouched: false,
    referralTypeError: '',
  }),
  computed: {
    referralTypeIsValid() {
      if (this.referralTypeTouched) {
        if (this.referral_type !== '') {
          this.referralTypeHasError = false;
          return true;
        } else {
          this.referralTypeHasError = true;
          this.referralTypeError = 'The field must be filled in!';
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    closeCreateReferralLinkModal() {
      this.resetFormBeforeClose();
      this.$modal.hide('CreateReferralLinkModal');
    },
    resetFormBeforeClose() {
      this.referral_type = '';
      this.referralTypeTouched = false;
    },
    async createReferralLink() {
      console.log('Input value:', this.referral_type);
      if (this.referralTypeIsValid) {
        try {
          const token = Cookies.get('accessToken');
          const response = await createReferralLink(token, this.referral_type);
          if (response.data.success) {

            this.$notify({
              group: 'notification-success',
              title: 'Success!',
              text: 'Referral link created successfully!',
            });
            this.$root.$emit('referralLinkCreated');
            this.closeCreateReferralLinkModal();
          } else {
            console.error('API error response:', response.data.message);
            this.closeCreateReferralLinkModal();

            this.$notify({
              group: 'notification-error',
              title: 'Error',
              text: response.data.message || 'Failed to create referral link',
            });
          }
        } catch (e) {
          console.error('Error creating referral link:', e);
          this.closeCreateReferralLinkModal();

          const errorMessage = e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'Failed to create referral link';
          this.$notify({
            group: 'notification-error',
            title: 'Error',
            text: errorMessage,
          });
        }
      }

    },
    openModal() {
      this.$modal.show('CreateReferralLinkModal');
    },
  },
};
