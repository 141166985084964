//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from 'vuex';

import VIcon from '@/components/UI/VIcon';
import VButton from '@/components/UI/VButton';
import VInput from '@/components/UI/VInput';

import { V_BUTTON } from '@/utils/constants/UI';
import { STRATEGY_TYPES } from '@/utils/constants/services';

export default {
  name: 'StrategyWithdrawModal',
  components: {
    VIcon,
    VButton,
    VInput,
  },

  data() {
    return {
      V_BUTTON,
      strategy: null,
      strategyAlias: null,
      form: {
        amount: '',
        password: '',
      },
      formHasError: {
        amount: false,
        password: false,
      },
      formErrors: {
        amount: '',
        password: '',
      },
    }
  },
  computed: {
    ...mapState('cabinet/profile', [
      'user',
    ]),
    wallet() {
      return {
        id: 1,
        name: this.$t('Main balance'),
        target: 'userBalance',
      }
    },
    strategiesIcon() {
      switch (this.strategy) {
        case STRATEGY_TYPES.BLUE_CONTROL:
          return 'BlueControlIcon';
      }
    },

    // amountIsValid() {
    //   if (this.form.amount.length >= 0) {
    //     this.formHasError.amount = false;
    //     return true;
    //   } else {
    //     this.formHasError.amount = true;
    //     return false;
    //   }
    // },
    passwordIsValid() {
      if (this.form.password.length >= 0) {
        this.formHasError.password = false;
        return true;
      } else {
        this.formHasError.password = true;
        return false;
      }
    },
  },

  methods: {
    ...mapMutations('cabinet/profile', [
      'UPDATE_USER_BALANCE_AFTER_TRANSACTIONS',
    ]),
    ...mapMutations('cabinet/services/earn', [
      'SET_STRATEGY',
    ]),

    ...mapActions('cabinet/services', [
      'withdrawIntoStrategy',
    ]),

    onOpen({ params }) {
      this.strategy = params.name;
      this.strategyAlias = params.alias;
    },
    closeStrategyWithdrawModal() {
      this.form.amount = '';
      this.form.password = '';

      this.formHasError.amount = false;
      this.formHasError.password = false;

      this.formErrors.address = '';
      this.formErrors.password = '';

      this.$modal.hide('StrategyWithdrawModal');
    },

    // depositMask() {
    //   let amount = this.form.amount.replace(/[^0-9]/g, '');
    //   this.form.amount = Number(amount).toLocaleString();
    // },
    setCurrentWallet(wallet) {
      this.currentWallet = wallet.id;
    },

    // inputAmountIsValid() {
    //   let amount = this.form.amount.replace(/[^0-9]/g, '');
    //
    //   if (this.form.amount.length === 0) {
    //     this.formHasError.amount = true;
    //     this.formErrors.amount = this.$t('The field must be filled in');
    //     return false;
    //   } else if (this.user.balance < amount) {
    //     this.formHasError.amount = true;
    //     this.formErrors.amount = this.$t('There are not enough funds on the balance');
    //     return false;
    //   } else {
    //     this.formHasError.amount = false;
    //     this.formErrors.amount = '';
    //     return true;
    //   }
    // },
    inputPasswordIsValid() {
      if (this.form.password.length === 0) {
        this.formHasError.password = true;
        this.formErrors.password = this.$t('The field must be filled in');
        return false;
      } else {
        this.formHasError.password = false;
        this.formErrors.password = '';
        return true;
      }
    },

    allFieldsAreValid() {
      // let amountIsValid = this.amountIsValid && this.inputAmountIsValid();
      // let passwordIsValid = this.passwordIsValid && this.inputPasswordIsValid();

      // return amountIsValid && passwordIsValid;
      return this.passwordIsValid && this.inputPasswordIsValid();
    },

    async sendWithdrawForm() {
      // let amount = this.form.amount.replace(/[^0-9]/g, '');

      try {
        if (this.allFieldsAreValid()) {
          const response = await this.withdrawIntoStrategy({
            // amount,
            strategyAlias: this.strategyAlias,
            password: this.form.password,
            type: 'withdraw',
          })

          if (response.data.success) {
            this.closeStrategyWithdrawModal();

            this.UPDATE_USER_BALANCE_AFTER_TRANSACTIONS(response.data.user.balance);
            this.SET_STRATEGY(response.data.data);

            this.$notify({
              group: 'notification-success',
              title: this.$t('Success'),
              text: this.$t('Notify text'),
            });
          }
        }
      } catch (e) {
        this.formHasError.password = true;
        this.formErrors.password = this.$t('Incorrect password');
        console.log(e)
      }
    },
  },

  i18n: {
    messages: {
      en: {
        'Withdraw': 'Withdraw',
        'Withdraw from': 'Withdraw from',
        'Main balance': 'Main balance',
        'Withdraw wallet': 'Withdraw wallet',
        'Only number': 'Only number',
        'Service': 'Service',
        'Withdraw amount': 'Withdraw amount',
        'Password': 'Password',
        'Enter password': 'Enter password',
        'The field must be filled in': 'The field must be filled in!',
        'There are not enough funds on the balance': 'There are not enough funds on the balance!',
        'Incorrect password': 'Incorrect password',
        'Success': 'Success!',
        'Notify text': 'The operation of withdrawing funds from the strategy has been completed!',
      },
      ru: {
        'Withdraw': 'Вывод',
        'Withdraw from': 'Вывод средств из',
        'Main balance': 'Основной баланс',
        'Withdraw wallet': 'Кошелек вывода',
        'Only number': 'Только числовое значение',
        'Service': 'Стратегия',
        'Withdraw amount': 'Сумма вывода',
        'Password': 'Пароль',
        'Enter password': 'Введите пароль',
        'The field must be filled in': 'Поле должно быть заполнено!',
        'There are not enough funds on the balance': 'На балансе недостаточно средств!',
        'Incorrect password': 'Неверный пароль!',
        'Success': 'Успешно!',
        'Notify text': 'Операция вывода средств из стратегии была выпронена!',
      },
    },
  },
}
