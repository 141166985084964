//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WalletDepositModal from '@/components/System/Modals/Wallet/DepositModal/WalletDepositModal';
import WalletWithdrawModal from '@/components/System/Modals/Wallet/WithdrawModal/WalletWithdrawModal';
import VerificationModal from '@/components/System/Modals/VerificationModal/VerificationModal';
import StrategyDepositModal from '@/components/System/Modals/Strategy/DepositModal/StrategyDepositModal';
import StrategyWithdrawModal from '@/components/System/Modals/Strategy/WithdrawModal/StrategyWithdrawModal';
import SettingsWalletModal from '@/components/System/Modals/SettingsWalletModal/SettingsWalletModal';
import TwoStepVerificationModal from '@/components/System/Modals/TwoStepVerification/TwoStepVerificationModal';
import CropModal from '@/components/System/Modals/CropModal/CropModal';
import CreateReferralLinkModal from '@/components/System/Modals/ReferralLinkmodal/CreateReferralLinkModal';

export default {
  name: 'TheModals',
  components: {
    WalletDepositModal,
    WalletWithdrawModal,
    VerificationModal,
    StrategyDepositModal,
    StrategyWithdrawModal,
    SettingsWalletModal,
    TwoStepVerificationModal,
    CropModal,
    CreateReferralLinkModal
  },
}
