//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex';

import VIcon from '@/components/UI/VIcon';
import VInput from '@/components/UI/VInput';
import VSelect from '@/components/UI/VSelect';
import VButton from '@/components/UI/VButton';

import { V_BUTTON } from '@/utils/constants/UI';

import { createWalletListRequest } from '@/requestManager/cabinet/settings/wallet';

export default {
  name: 'SettingsWalletModal',
  components: {
    VIcon,
    VInput,
    VSelect,
    VButton,
  },

  data: () => ({
    V_BUTTON,
    form: {
      token: {
        id: null,
        title: null,
        image: null,
      },
      address: null,
      title: null,
    },
    formHasError: {
      token: false,
      address: false,
      title: false,
    },
    fieldsWereTouched: {
      token: false,
      address: false,
      title: false,
    },
    formErrors: {
      token: '',
      address: '',
      title: '',
    },
  }),
  computed: {
    ...mapState('general', [
      'fundWallets',
    ]),
    tokenIsValid() {
      if (this.fieldsWereTouched.token) {
        if (this.form.token.id !== null && this.form.token.id !== '') {
          this.formHasError.token = false;
          this.fieldsWereTouched.token = false;
          return true;
        } else {
          this.formHasError.token = true;
          this.formErrors.token = this.$t('The field must be filled in');
          return false;
        }
      } else {
        return true
      }
    },
    addressIsValid() {
      if (this.fieldsWereTouched.address) {
        if (this.form.address !== null && this.form.address !== '') {
          this.formHasError.address = false;
          this.fieldsWereTouched.address = false;
          return true;
        } else {
          this.formHasError.address = true;
          this.formErrors.address = this.$t('The field must be filled in');
          return false;
        }
      } else {
        return true
      }
    },
    titleIsValid() {
      if (this.fieldsWereTouched.title) {
        if (this.form.title !== null && this.form.title !== '') {
          this.formHasError.title = false;
          this.fieldsWereTouched.title = false;
          return true;
        } else {
          this.formHasError.title = true;
          this.formErrors.title = this.$t('The field must be filled in');
          return false;
        }
      } else {
        return true
      }
    },

    availableTokens() {
      return this.fundWallets.map(wallet => wallet.currency);
    },
  },

  methods: {
    ...mapMutations('cabinet/settings/wallet', [
      'ADD_WALLET_TO_LIST',
    ]),

    tokenSelectIsValid() {
      if (this.form.token.id === null || this.form.token.id === '') {
        this.formErrors.token = this.$t('The field must be filled in');
        this.formHasError.token = true;
        return false;
      } else {
        this.formHasError.token = false;
        this.formErrors.token = '';
        return true;
      }
    },
    inputAddressIsValid() {
      if (this.form.address === null || this.form.address === '') {
        this.formErrors.address = this.$t('The field must be filled in');
        this.formHasError.address = true;
        return false;
      } else {
        this.formHasError.address = false;
        this.formErrors.address = '';
        return true;
      }
    },
    inputTitleIsValid() {
      if (this.form.title === null || this.form.title === '') {
        this.formErrors.title = this.$t('The field must be filled in');
        this.formHasError.title = true;
        return false;
      } else {
        this.formHasError.title = false;
        this.formErrors.title = '';
        return true;
      }
    },

    allFieldsAreValid() {
      let token = this.tokenIsValid && this.tokenSelectIsValid();
      let address = this.addressIsValid && this.inputAddressIsValid();
      let title = this.titleIsValid && this.inputTitleIsValid();

      return token && address && title;
    },

    resetFormBeforeClose() {
      this.form.token.image = null;
      this.form.token.title = null;
      this.form.token.id = null;
      this.form.address = null;
      this.form.title = null;
    },
    closeSettingWalletModal() {
      this.resetFormBeforeClose();

      this.$modal.hide('SettingsWalletModal');
    },
    setWalletOption(wallet) {
      this.form.token.image = wallet.image;
      this.form.token.title = wallet.title;
      this.form.token.id = wallet.id;
      this.fieldsWereTouched.token = true;
    },

    async sendWalletInvest() {
      if (this.allFieldsAreValid()) {
        try {
          const response = await createWalletListRequest({
            currencyId: this.form.token.id,
            address: this.form.address,
            title: this.form.title,
          });

          if (response.data.success) {
            this.ADD_WALLET_TO_LIST(response.data.data);
            this.closeSettingWalletModal();
            this.$notify({
              group: 'notification-success',
              title: this.$t('Success'),
              text: this.$t('Notify text'),
            });
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },

  i18n: {
    messages: {
      en: {
        'Title': 'Adding a withdrawal address',
        'Token': 'Token',
        'Address (ERC-20)': 'Address (ERC-20)',
        'Label address': 'Label address',
        'Add a name to this address': 'Enter name of address here',
        'Label address placeholder': 'Enter a value',
        'Only numbers and Latin characters.': 'Only numbers and Latin characters.',
        '4-20 characters. Example: Alexey INDEFI.': '4-20 characters. Example: Alexey INDEFI.',
        'Save': 'Save',
        'The field must be filled in': 'The field must be filled in!',
        'Success': 'Success!',
        'Notify text': 'The wallet was successfully added!',
      },
      ru: {
        'Title': 'Добавление дреса для вывода средств',
        'Token': 'Монета',
        'Address (ERC-20)': 'Адресс (ERC-20)',
        'Label address': 'Метка адреса',
        'Add a name to this address': 'Введите название адреса',
        'Label address placeholder': 'Введите значение',
        'Only numbers and Latin characters.': 'Только цифры и латинские символы.',
        '4-20 characters. Example: Alexey INDEFI.': '4-20 знаков. Пример: Алексей INDEFI.',
        'Save': 'Сохранить',
        'The field must be filled in': 'Поле должно быть заполнено!',
        'Success': 'Успешно!',
        'Notify text': 'Кошелек был успешно добавлен!',
      },
    },
  },
}
