//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

import VButton from '@/components/UI/VButton';
import VIcon from '@/components/UI/VIcon';

import { V_BUTTON } from '@/utils/constants/UI';

import { uploadProfileImageRequest } from '@/requestManager/cabinet/settings/profile';

export default {
  name: 'CropModal',
  components: {
    VueCropper,
    VButton,
    VIcon,
  },

  data: () => ({
    V_BUTTON,
    file: null,
    imageBase64: null,

    coordinates: {
      x: null,
      y: null,
    },
    sizes: {
      height: null,
      width: null,
    },

    buttonCropIsDisabled: false,
  }),

  methods: {
    onOpen({ params }) {
  this.file = params.file; // Đảm bảo rằng `params.file` chứa một tệp ảnh hợp lệ
  this.imageBase64 = params.imageBase64;
  this.resultHandler = params.resultHandler;
  this.aspectRatio = params.aspectRatio;
  this.name = params.name;

  // Kiểm tra `this.file` có phải là một tệp ảnh hợp lệ không
  console.log('File:', this.file);
},
    getCropperData() {
      const cropperData = this.$refs.cropper.getData();

      this.coordinates = {
        x: cropperData.x,
        y: cropperData.y,
      };

      this.sizes = {
        width: cropperData.width,
        height: cropperData.height,
      };

      this.saveImage();
    },

    async saveImage() {
    this.buttonCropIsDisabled = true;

    const returnNaturalNumberOrZero = value => {
      return value > 0
        ? parseFloat(value).toFixed(0)
        : 0;
    };

    const formData = new FormData();
    formData.append('image', this.file); 
    formData.append('x', returnNaturalNumberOrZero(this.coordinates.x));
    formData.append('y', returnNaturalNumberOrZero(this.coordinates.y));
    formData.append('height', returnNaturalNumberOrZero(this.sizes.height));
    formData.append('width', returnNaturalNumberOrZero(this.sizes.width));

    // Kiểm tra dữ liệu formData
    for (let pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]); 
    }

    try {
      const response = await uploadProfileImageRequest(formData);

      if (!response?.data.success) {
        console.error('Upload failed:', response.data.errors);
        return;
      }

      this.resultHandler({
        url: response.data.data.url,
        id: response.data.data.id,
        name: response.data.data.name,
      });

      this.$modal.hide('CropModal');
    } catch (e) {
      console.error('Error uploading image:', e);
    } finally {
      this.buttonCropIsDisabled = false;
    }
  },
  },

  i18n: {
    messages: {
      en: {
        'Crop the photo': 'Crop the photo',
      },
      ru: {
        'Crop the photo': 'Обрежте фотографию',
      },
    },
  },
}
